import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

const SessionId = 'sessionId'

const ShareTokenKey = 'shareToken'


export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getSessionId() {
  return Cookies.get(SessionId)
}

export function setSessionId(sessionId) {
  return Cookies.set(SessionId, sessionId)
}


export function getShareToken() {
  return Cookies.get(ShareTokenKey)
}

export function setShareToken(shareToken) {
  return Cookies.set(ShareTokenKey, shareToken)
}

const TenantKey="TenantLogin-Key"

export function getTenant(){
  return Cookies.get(TenantKey)
}
export function setTenant(tenant){
  return Cookies.set(TenantKey, tenant);
}