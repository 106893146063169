var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.option['hide'] == null || _vm.option['hide'] == false)?_c('div',{staticClass:"anji-card"},[_c('div',{staticClass:"card-head"},[_vm._v(_vm._s(_vm.option.title))]),_c('div',{staticClass:"card-body"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('el-table',{attrs:{"data":_vm.formRecordsUndelete,"border":"","row-class-name":_vm.tableRowClassAdapter},on:{"selection-change":_vm.handleSelectionChange,"row-click":_vm.handleTableRowClick}},[_c('el-table-column',{attrs:{"label":"序号","min-width":"50","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}],null,false,3056706777)}),_vm._l((_vm.option.columns),function(item){return [(
              _vm.fieldIsHide(item.tableHide) != true &&
                item.columnType != 'expand'
            )?_c('el-table-column',{key:item.field,attrs:{"label":item.label,"min-width":item.minWidth || 110,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'records.' + scope.$index + '.' + item.field,"rules":item.rules}},[(item.inputType == 'input')?_c('el-input',{attrs:{"size":"small","placeholder":item.placeholder || '请输入',"clearable":item.clearable !== false,"disabled":_vm.saveButtonStatus[scope.$index] == 'inShow' ||
                      item.disabled},on:{"change":function (value) { return _vm.tableRowChange(scope.$index, item.field, value); }},model:{value:(scope.row[item.field]),callback:function ($$v) {_vm.$set(scope.row, item.field, $$v)},expression:"scope.row[item.field]"}}):(item.inputType == 'switch')?_c('el-switch',{attrs:{"disabled":_vm.saveButtonStatus[scope.$index] == 'inShow' ||
                      item.disabled,"active-value":item.switchOption.disableValue,"inactive-value":item.switchOption.enableValue,"active-color":"#5887fb","inactive-color":"#ccc"},on:{"change":function (value) { return _vm.tableRowChange(scope.$index, item.field, value); }},model:{value:(scope.row[item.field]),callback:function ($$v) {_vm.$set(scope.row, item.field, $$v)},expression:"scope.row[item.field]"}}):(item.inputType == 'input-number')?_c('el-input',{attrs:{"size":"small","min":item.inputNumberOption.min,"max":item.inputNumberOption.max,"placeholder":item.placeholder || '请输入',"clearable":item.clearable !== false,"disabled":_vm.saveButtonStatus[scope.$index] == 'inShow' ||
                      item.disabled},on:{"change":function (value) { return _vm.tableRowChange(scope.$index, item.field, value); }},model:{value:(scope.row[item.field]),callback:function ($$v) {_vm.$set(scope.row, item.field, $$v)},expression:"scope.row[item.field]"}}):(item.inputType == 'anji-input')?_c('anji-input',{attrs:{"default-value":item.defaultValue,"unit":item.anjiInput.unit,"conversion":item.anjiInput.conversion,"placeholder":item.placeholder || '请输入',"clearable":item.clearable !== false,"disabled":_vm.saveButtonStatus[scope.$index] == 'inShow' ||
                      item.disabled},on:{"change":function (value) { return _vm.tableRowChange(scope.$index, item.field, value); }},model:{value:(scope.row[item.field]),callback:function ($$v) {_vm.$set(scope.row, item.field, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row[item.field]"}}):(item.inputType == 'anji-select')?_c('anji-select',{attrs:{"multiple":item.anjiSelectOption.multiple,"default-value":item.defaultValue,"dict-code":item.anjiSelectOption.dictCode,"url":item.anjiSelectOption.url,"method":item.anjiSelectOption.method,"query-param":item.anjiSelectOption.queryParam,"option":item.anjiSelectOption.option,"label":item.anjiSelectOption.label,"disabled-options":item.anjiSelectOption.disabledOptions,"disabled":_vm.saveButtonStatus[scope.$index] == 'inShow' ||
                      item.disabled},on:{"change":function (value, option) { return _vm.tableRowChange(scope.$index, item.field, value, option); }},model:{value:(scope.row[item.field]),callback:function ($$v) {_vm.$set(scope.row, item.field, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row[item.field]"}}):(item.inputType.indexOf('date') >= 0)?_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"placeholder":item.placeholder || '请选择',"type":item.inputType,"clearable":item.clearable !== false,"disabled":_vm.saveButtonStatus[scope.$index] == 'inShow' ||
                      item.disabled},on:{"change":function (value) { return _vm.tableRowChange(scope.$index, item.field, value); }},model:{value:(scope.row[item.field]),callback:function ($$v) {_vm.$set(scope.row, item.field, $$v)},expression:"scope.row[item.field]"}}):_c('el-input',{attrs:{"placeholder":"组件不支持此类型表单请至组件内部自行扩展","disabled":""}})],1)]}}],null,true)}):_vm._e()]}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleAddOrUpdate(scope.row, scope.$index)}}},[_vm._v(_vm._s(_vm.getRowEditButton(scope.$index)))]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleDelete(scope.row, scope.$index)}}},[_vm._v("删除")])]}}],null,false,868736448)})],2)],1),(_vm.modelType != 'view')?_c('button',{staticClass:"table-add-row-button",on:{"click":_vm.handleAdd}},[_c('i',{staticClass:"el-icon-plus"}),_c('span',[_vm._v("新增")])]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }