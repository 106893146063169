
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1607776376762",
    "class": "icon",
    "viewBox": "0 0 1160 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "12593",
    "width": "72.5",
    "height": "64"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M200.026637 428.750277h227.302997v-227.302996a200.310766 200.310766 0 0 0-198.890122-198.890122 227.302997 227.302997 0 0 0-227.302997 227.302996 200.310766 200.310766 0 0 0 198.890122 198.890122z m26.139845-369.367369a142.064373 142.064373 0 0 1 142.064373 142.064373v170.477247h-170.477248a142.064373 142.064373 0 0 1-142.064373-142.064373 170.477248 170.477248 0 0 1 170.477248-170.477247zM1.420644 677.36293a227.302997 227.302997 0 0 0 227.302996 227.302997 200.310766 200.310766 0 0 0 198.890122-198.890122v-227.302997h-227.302996a200.310766 200.310766 0 0 0-198.890122 198.890122z m369.367369 26.139845a142.064373 142.064373 0 0 1-142.064373 142.064373 170.477248 170.477248 0 0 1-170.477247-170.477248 142.064373 142.064373 0 0 1 142.064373-142.064373h170.477247zM702.36626 475.915649h-227.302997v227.302997a200.310766 200.310766 0 0 0 198.890122 198.890122 227.302997 227.302997 0 0 0 227.302997-227.302997 200.310766 200.310766 0 0 0-198.890122-198.890122z m-26.139845 369.36737a142.064373 142.064373 0 0 1-142.064373-142.064373v-170.477248h170.477248a142.064373 142.064373 0 0 1 142.064373 142.064373 170.477248 170.477248 0 0 1-170.477248 170.477248zM900.972253 227.302997a227.302997 227.302997 0 0 0-227.302997-227.302997 200.310766 200.310766 0 0 0-198.890122 198.890122v227.302997h227.302997a200.310766 200.310766 0 0 0 198.890122-198.890122z m-369.36737-26.139845a142.064373 142.064373 0 0 1 142.064373-142.064373 170.477248 170.477248 0 0 1 170.477248 170.477248 142.064373 142.064373 0 0 1-142.064373 142.064373h-170.477248z",
        "p-id": "12594"
      }
    }
  ]
})
    }
  