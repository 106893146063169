
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1644377628778",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "3080",
    "width": "200",
    "height": "200"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M864 679.338667H810.666667v-123.989334a64 64 0 0 0-64-64h-210.688V387.285333h88.021333a32 32 0 0 0 32-32v-128a32 32 0 0 0-32-32h-224a32 32 0 0 0-32 32v128c0 17.664 14.336 32 32 32h88.021333v103.978667H277.333333A64 64 0 0 0 213.333333 555.349333v123.989334H160a32 32 0 0 0-32 32v128c0 17.664 14.336 32 32 32H298.666667a32 32 0 0 0 32-32v-128a32 32 0 0 0-32-32h-37.333334v-91.989334c0-35.328 12.672-48.042667 48-48.042666h178.688v140.032h-45.354666a32 32 0 0 0-32 32v128c0 17.664 14.336 32 32 32h138.666666a32 32 0 0 0 32-32v-128a32 32 0 0 0-32-32h-45.354666V539.306667h178.688c35.328 0 48 12.714667 48 48.042666v91.989334H725.333333a32 32 0 0 0-32 32v128c0 17.664 14.336 32 32 32h138.666667a32 32 0 0 0 32-32v-128a32 32 0 0 0-32-32z",
        "p-id": "3081",
        "fill": "#707070"
      }
    }
  ]
})
    }
  