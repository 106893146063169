
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1576042673958",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "1110",
    "width": "81",
    "height": "81"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M692 792H332c-150 0-270-120-270-270s120-270 270-270h360c150 0 270 120 270 270 0 147-120 270-270 270zM332 312c-117 0-210 93-210 210s93 210 210 210h360c117 0 210-93 210-210s-93-210-210-210H332z",
        "p-id": "1111"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M341 522m-150 0a150 150 0 1 0 300 0 150 150 0 1 0-300 0Z",
        "p-id": "1112"
      }
    }
  ]
})
    }
  