<template>
  <!-- <global-footer class="footer custom-render">
   
  </global-footer> -->
</template>

<script>
import { GlobalFooter } from '@/components/ProLayout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>
