
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1644344849977",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "7283",
    "width": "200",
    "height": "200"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M685.7 829.5c-60.4 32.3-128.5 47.5-196.9 44.1-14.2-0.8-26.2 10.2-26.9 24.3-0.7 14.1 10.2 26.2 24.3 26.9 7.2 0.4 14.4 0.5 21.6 0.5 70.4 0 139.8-17.3 202.1-50.6 12.5-6.7 17.2-22.2 10.5-34.7-6.7-12.5-22.2-17.2-34.7-10.5zM764 172.9C624.1 80.4 441.9 78 299.6 166.7 176.2 243.7 101 376.4 98.3 521.8c-2.6 145.8 75 280 202.7 350.3 11.2 6.2 22.8 11.9 34.4 17 3.4 1.5 6.9 2.2 10.3 2.2 9.9 0 19.3-5.7 23.5-15.3 5.7-13-0.2-28.1-13.2-33.8-10.2-4.5-20.4-9.5-30.2-14.9-111-61.1-178.5-177.8-176.2-304.5 2.3-128 68.6-244.8 177.1-312.5 125.3-78.1 285.8-76 409 5.4C843 286.5 895.2 418.2 865.6 543.4c-14.1 59.6-59.5 107-118.5 123.5-28.6 8-58.3 0.4-79.5-20.4-21.2-20.8-29.4-50.4-21.9-79.1l61.1-234.8c3.6-13.7-4.7-27.7-18.3-31.3-13.7-3.5-27.7 4.6-31.3 18.3L639 389.9c-18-38.8-47.7-69.3-87-84.5-93.2-36.1-205.2 27.8-249.5 142.4-44.3 114.6-4.5 237.1 88.7 273.2 17.7 6.8 36 10.1 54.4 10.1 58.2 0 117.2-32.5 158.6-86.9 6.7 14.2 15.9 27.4 27.6 38.9 34.5 33.8 82.8 46.2 129.3 33.1 76.9-21.6 136.1-83.3 154.5-161.1C950 409.2 889.1 255.5 764 172.9zM409.7 673.1c-66.9-25.9-93.5-118.7-59.4-206.9 28.1-72.6 88.7-119.8 146.6-119.8 12.4 0 24.8 2.2 36.6 6.8 66.9 25.9 93.5 118.7 59.4 206.9-34.2 88.2-116.4 138.9-183.2 113z",
        "p-id": "7284",
        "fill": "#707070"
      }
    }
  ]
})
    }
  