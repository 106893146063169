export default {
  'tag.document': 'https://1x.antdv.com/docs/vue/introduce-cn/',
  'tag.input.label': '单行文本',
  'tag.input.document': 'https://1x.antdv.com/components/input-cn/',
  'tag.textarea.label': '多行文本',
  'tag.textarea.document': 'https://1x.antdv.com/components/input-cn/',
  'tag.input.password.label': '密码',
  'tag.input.password.document': 'https://1x.antdv.com/components/input-cn/',
  'tag.input.number.label': '计数器',
  'tag.input.number.document': 'https://1x.antdv.com/components/input-number-cn/',
  'tag.input.search.label': '搜索',
  'tag.input.search.document': 'https://1x.antdv.com/components/input-cn/',
  'tag.auto.complete.label': '自动完成',
  'tag.auto.complete.document': 'https://1x.antdv.com/components/auto-complete-cn/',
  'tag.mentions.label': '提及',
  'tag.mentions.document': 'https://1x.antdv.com/components/mentions-cn/',
  'tag.select.label': '下拉选择',
  'tag.select.document': 'https://1x.antdv.com/components/select-cn/',
  'tag.cascader.label': '级联选择',
  'tag.cascader.document': 'https://1x.antdv.com/components/cascader-cn/',
  'tag.tree.select.label': '树选择',
  'tag.tree.select.document': 'https://1x.antdv.com/components/tree-select-cn/',
  'tag.transfer.label': '穿梭框',
  'tag.transfer.document': 'https://1x.antdv.com/components/transfer-cn/',
  'tag.radio.group.label': '单选框组',
  'tag.radio.group.document': 'https://1x.antdv.com/components/radio-cn/',
  'tag.checkbox.group.label': '多选框组',
  'tag.checkbox.group.document': 'https://1x.antdv.com/components/checkbox-cn/',
  'tag.switch.label': '开关',
  'tag.switch.document': 'https://1x.antdv.com/components/switch-cn/',
  'tag.slider.label': '滑块',
  'tag.slider.document': 'https://1x.antdv.com/components/slider-cn/',
  'tag.rate.label': '评分',
  'tag.rate.document': 'https://1x.antdv.com/components/rate-cn/',
  'tag.upload.label': '上传',
  'tag.upload.document': 'https://1x.antdv.com/components/upload-cn/',
  'tag.upload.button.text': '点击上传',
  'tag.time.picker.label': '时间选择',
  'tag.time.picker.document': 'https://1x.antdv.com/components/time-picker-cn/',
  'tag.date.picker.label': '日期选择',
  'tag.date.picker.document': 'https://1x.antdv.com/components/date-picker-cn/',
  'tag.month.picker.label': '月份选择',
  'tag.month.picker.document': 'https://1x.antdv.com/components/date-picker-cn/',
  'tag.week.picker.label': '周选择',
  'tag.week.picker.document': 'https://1x.antdv.com/components/date-picker-cn/',
  'tag.range.picker.label': '日期范围',
  'tag.range.picker.document': 'https://1x.antdv.com/components/date-picker-cn/',
  'tag.row.label': '行容器',
  'tag.row.document': 'https://1x.antdv.com/components/grid-cn/',
  'tag.button.label': '按钮',
  'tag.button.document': 'https://1x.antdv.com/components/button-cn/',
  'tag.button.slot.default': '主要按钮'
}
