export default {
  'base.page': '页面',
  'base.modal': '弹窗',
  'base.filename': '文件名',
  'base.submit': '提交',
  'base.reset': '重置',
  'base.ok': '确定',
  'base.cancel': '取消',
  'base.close': '关闭',
  'base.refresh': '刷新',
  'base.hint': '提示',
  'base.add': '新增',
  'base.remove': '删除',
  'base.copy': '复制',
  'base.successfully': '成功',
  'base.err': '错误',
  'base.input_components': '输入型组件',
  'base.optional_components': '选择型组件',
  'base.layout_components': '布局型组件',
  'base.run': '运行',
  'base.view.json': '提交',
  'base.export.file': '导出文件',
  'base.export.vue.file': '导出vue文件',
  'base.export.json.file': '导出JSON文件',
  'base.copy.code': '复制代码',
  'base.copy.json.code': '提交',
  // 'base.copy.json.code': '复制JSON代码',
  'base.empty': '清空',
  'base.left.drag': '从左侧拖入或点选组件进行表单设计',
  'base.choose.build.type': '选择生成类型',
  'base.code.copy.successfully': '代码已复制到剪切板，可粘贴',
  'base.code.copy.failed': '代码复制失败',
  'base.confirm.empty.components': '确定要清空所有组件吗？',
  'base.js.format.failed': 'js格式不能识别，仅支持修改export default的对象内容',
  'base.js.err': 'js错误',
  'base.layout.format.failed': '没有匹配的layout',
  'base.json.format.failed': 'JSON格式错误，请检查',
  'base.use.export.export': '请使用export default',
  'base.resource.reference': '资源引用',
  'base.external.resource.reference': '外部资源引用',
  'base.build.type': '生成类型',
  'base.cssjs.resource.url.placeholder': '请输入 css 或 js 资源路径',
  'base.add.other': '添加其他',
  'base.resource.existed': '资源已存在',
  'base.component.properties': '组件属性',
  'base.component.size': '组件尺寸',
  'base.component.size.large': '较大',
  'base.component.size.default': '默认',
  'base.component.size.small': '较小',
  'base.option': '选项',
  'base.option.name': '选项名',
  'base.option.value': '选项值',
  'base.option.type': '选项样式',
  'base.string': '字符串',
  'base.letter': '英文',
  'base.letter.number': '英文或数字',
  'base.letter.number.underline': '英文、数字或下划线',
  'base.chinese.characters': '汉字',
  'base.mail': '邮箱',
  'base.number': '数字',
  'base.enter': '请输入',
  'base.choose': '请选择',
  'base.component.type': '组件类型',
  'base.field.name': '字段名',
  'base.component.name': '组件名',
  'base.title': '标题',
  'base.top.title': '顶部标题',
  'base.source.title': '源标题',
  'base.target.title': '目标标题',
  'base.placeholder': '占位提示',
  'base.start.placeholder': '开始占位',
  'base.end.placeholder': '结束占位',
  'base.form.properties': '表单属性',
  'base.form.grid': '表单栅格',
  'base.form.name': '表单名',
  'base.form.model': '表单模型',
  'base.form.rules': '校验模型',
  'base.form.layout': '表单布局',
  'base.form.layout.horizontal': '水平',
  'base.form.layout.vertical': '垂直',
  'base.form.layout.inline': '内联',
  'base.form.layout.inline.tip': '内联样式请点击运行查看',
  'base.form.label.span': '标签长度',
  'base.form.label.offset': '标签偏移量',
  'base.form.wrapper.span': '控件长度',
  'base.form.wrapper.offset': '控件偏移量',
  'base.form.disabled': '禁用表单',
  'base.form.button': '表单按钮',
  'base.un.focused.component.border': '显示未选中组件边框',
  'base.component.doc': '查看组件文档',
  'base.icon.component': '图标选择器',
  'base.label.align': '标签对齐',
  'base.label.align.left': '左对齐',
  'base.label.align.right': '右对齐',
  'base.grid.space': '栅格间隔',
  'base.layout.model': '布局模式',
  'base.horizontal.arrangement': '水平排列',
  'base.vertical.arrangement': '垂直排列',
  'base.component.width': '组件宽度',
  'base.component.height': '组件宽度',
  'base.default.value': '默认值',
  'base.prefix': '前缀',
  'base.suffix': '后缀',
  'base.icon.before': '前图标',
  'base.icon.after': '后图标',
  'base.icon.choose': '选择图标',
  'base.button.icon': '按钮图标',
  'base.button.type': '按钮图标',
  'base.button.shape': '按钮形状',
  'base.button.text': '按钮文字',
  'base.button.style': '按钮样式',
  'base.button.style.outline': '按钮样式',
  'base.button.style.solid': '按钮样式',
  'base.button.block': '宽度适应父级',
  'base.separator': '分隔符',
  'base.min.row': '最小行数',
  'base.max.row': '最大行数',
  'base.min': '最小值',
  'base.max': '最大值',
  'base.total': '总数',
  'base.step': '步长',
  'base.most.enter': '最多输入',
  'base.most.enter.after': '个字符',
  'base.maxlength': '字符长度',
  'base.checked': '选中时的内容',
  'base.unchecked': '非选中时的内容',
  'base.time.choose': '时间选择',
  'base.time.format': '时间格式',
  'base.show.today': '展示今天按钮',
  'base.use12.hours': '12 小时制',
  'base.file.field.name': '文件字段名',
  'base.file.type': '文件类型',
  'base.file.size': '文件大小',
  'base.file.multiple': '多选文件',
  'base.file.should.choose.type': '应该选择此类型文件:',
  'base.file.size.overflow': '文件大小超过',
  'base.upload.url': '上传地址',
  'base.image': '图片',
  'base.video': '视频',
  'base.audio': '音频',
  'base.list.type': '列表类型',
  'base.datasource': '数据源',
  'base.backfill': '键盘回填',
  'base.backfill.tip': '使用键盘选择选项的时候把选中项回填到输入框中',
  'base.default.open': '默认展开下拉菜单',
  'base.placement': '弹出层展示位置',
  'base.trigger.keyword': '触发关键字',
  'base.show.name': '显示名',
  'base.data.value': '数据值',
  'base.data.type': '数据类型',
  'base.data.dynamic': '动态数据',
  'base.data.static': '静态数据',
  'base.data.path': '数据位置',
  'base.add.data': '添加数据',
  'base.url': '接口地址',
  'base.prop.label': '标签键名',
  'base.prop.value': '值键名',
  'base.prop.children': '子级键名',
  'base.add.parent': '添加父级',
  'base.default': '默认',
  'base.button': '按钮',
  'base.show.label': '显示标签',
  'base.allow.half': '允许半选',
  'base.character': '自定义字符',
  'base.reverse': '反转',
  'base.range': '范围选择',
  'base.word.limit': '输入统计',
  'base.expand.trigger': '任选层级',
  'base.change.on.select': '选择即改变',
  'base.allow.clear': '能否清空',
  'base.read.only': '是否只读',
  'base.disabled': '是否禁用',
  'base.show.search': '能否搜索',
  'base.mode': '模式',
  'base.multiple': '多选',
  'base.required': '是否必填',
  'base.dropdown.matc.select.width': '下拉菜单和选择器同宽',
  'base.tree.default.expand.all': '默认展开节点',
  'base.tree.checkable': '显示勾选框',
  'base.tree.check.strictly': '节点完全受控',
  'base.label.in.value': 'labelInValue',
  'base.layout.tree': '布局结构树',
  'base.reg.list': '正则校验',
  'base.pattern': '表达式',
  'base.enter.pattern': '请输入或选择正则',
  'base.error.message': '错误提示',
  'base.add.reg': '添加规则',
  'base.select.least.one': '请至少选择一个',
  'base.not.null': '不能为空',
  'base.filter.option': '是否根据输入项进行筛选',
  'base.icon.directional': '方向性图标',
  'base.icon.suggested': '提示建议性图标',
  'base.icon.editor': '编辑类图标',
  'base.icon.data': '数据类图标',
  'base.icon.brand.logo': '品牌和标识',
  'base.icon.application': '网站通用图标',
  'base.add.option': '添加选项'
}
