
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1608880266404",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "7147",
    "width": "64",
    "height": "64"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M438.857143 950.857143h512V585.142857h-237.714286a54.857143 54.857143 0 0 1-54.857143-54.857143V292.571429h-219.428571v658.285714z m146.285714-822.857143v-36.571429c0-9.728-8.557714-18.285714-18.285714-18.285714h-402.285714c-9.728 0-18.285714 8.557714-18.285715 18.285714v36.571429c0 9.728 8.557714 18.285714 18.285715 18.285714h402.285714c9.728 0 18.285714-8.557714 18.285714-18.285714zM731.428571 512h170.861715L731.428571 341.138286V512z m292.571429 73.142857v384a54.857143 54.857143 0 0 1-54.857143 54.857143h-548.571428a54.857143 54.857143 0 0 1-54.857143-54.857143V877.714286H54.857143A54.857143 54.857143 0 0 1 0 822.857143v-768A54.857143 54.857143 0 0 1 54.857143 0h621.714286A54.857143 54.857143 0 0 1 731.428571 54.857143v187.428571c7.424 4.571429 14.299429 9.728 20.553143 15.981715l233.142857 233.142857c21.723429 21.723429 38.838857 63.414857 38.838858 93.696z",
        "p-id": "7148"
      }
    }
  ]
})
    }
  