// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import permission from './directive/permission'
import style from './directive/style'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@/components/ProLayout'
import FooterToolBar from '@/components/FooterToolbar'
import FileUpload from '@/components/FileUpload'
import themePluginConfig from '../config/themePluginConfig'
import { TableSetting } from '@/components'

import '@/assets/styles/index.scss' // global css

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import { getDicts } from '@/api/system/dict/data'
import { getConfigKey } from '@/api/system/config'
import {
  addDateRange,
  handleTree,
  parseTime,
  resetForm,
  selectDictLabel,
  selectDictLabels,
  tableSorter
} from '@/utils/hipi'
import { download } from '@/utils/request'

import '@/styles/index.scss'
import '@/utils/filter' // global filter
import Tinymce from '@/components/tinymce/index.vue'
import AMapView from '@/components/amap/AMapView.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

Vue.use(Antd)


//开始-----------report需要的包;
import echarts from 'echarts';
// 全局定义echarts
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
//import 'echarts-liquidfill'
// import 'echarts-gl'
Vue.component('v-chart', ECharts)
// 分页的全局size配置;
Vue.prototype.$pageSizeAll = [10, 50, 100, 200, 500]
import draggable from "vuedraggable";
Vue.component('avue-draggable', draggable)

import mixins from '@/report/mixins'
Vue.mixin(mixins) //开启这个会影响新增按钮冲突，目前屏蔽无影响


import anjiCrud from '@/components/Report/AnjiPlus/anji-crud/anji-crud'
import anjiSelect from '@/components/Report/AnjiPlus/anji-select'
import anjiUpload from '@/components/Report/AnjiPlus/anji-upload'
Vue.component('anji-upload', anjiUpload)
Vue.component('anji-crud', anjiCrud)
Vue.component('anji-select', anjiSelect)


// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// Vue.use(ElementUI);

import Element from 'element-ui'
import './assets/styles/element-variables.scss'
Vue.use(Element)


Vue.component('AMapView', AMapView)
Vue.component('tinymce', Tinymce)

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.tableSorter = tableSorter
Vue.config.productionTip = false

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)
Vue.component('footer-tool-bar', FooterToolBar)
Vue.component('file-upload', FileUpload)
Vue.component('table-setting', TableSetting)

Vue.use(permission)
Vue.use(style)

window.umi_plugin_ant_themeVar = themePluginConfig.theme


import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
Vue.use(Avue);

import * as filter from './filter'
// register global filter.
Object.keys(filter).forEach(key => {
  Vue.filter(key, filter[key])
})

// new Vue({
//   router,
//   store,
//   i18n,
//   // init localstorage, vuex
//   created: bootstrap,
//   render: h => h(App)
// }).$mount('#app')

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: h => h(App)
})
