
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1543827393750",
    "class": "icon",
    "style": "",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "4695",
    "width": "200",
    "height": "200"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M64 64V640H896V64H64zM0 0h960v704H0V0z",
        "p-id": "4696"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M192 896H768v64H192zM448 640H512v256h-64z",
        "p-id": "4697"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M479.232 561.604267l309.9904-348.330667-47.803733-42.5472-259.566934 291.669333L303.957333 240.008533 163.208533 438.6048l52.224 37.009067 91.6224-129.28z",
        "p-id": "4698"
      }
    }
  ]
})
    }
  