
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1607776664012",
    "class": "icon",
    "viewBox": "0 0 1031 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "14772",
    "width": "64.4375",
    "height": "64"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M514.60052 137C229.50052 137.5-0.39948 368.2 0.00052 653.3 0.10052 729.2 16.60052 801.2 46.20052 866c8.5 18.7 18.2 36.9 28.8 54.3 2.9 4.8 8.1 7.7 13.7 7.7h853.7c5.6 0 10.8-2.9 13.7-7.7 10.6-17.5 20.3-35.6 28.8-54.3 29.6-65 46.2-137.4 46.2-213.5-0.1-285-231.4-516-516.5-515.5z m416.9 691.2c-5.5 12.9-11.5 25.5-18.1 37.8H117.50052c-6.6-12.3-12.6-24.9-18.1-37.8C75.90052 772.6 64.00052 713.5 64.00052 652.5c0-61 11.9-120.1 35.5-175.7 22.7-53.8 55.3-102 96.8-143.5s89.8-74 143.5-96.8C395.40052 213 454.50052 201 515.50052 201s120.1 11.9 175.7 35.5c53.8 22.7 102.1 55.3 143.5 96.8 41.5 41.5 74 89.8 96.8 143.5C955.00052 532.4 967.00052 591.5 967.00052 652.5c0 61-11.9 120.1-35.5 175.7z",
        "p-id": "14773"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M623.90052 507.6c-76.2 163.5-100.7 191.2-103.3 193.8-12.1 12.1-28.2 18.7-45.3 18.7s-33.2-6.7-45.3-18.7c-25-25-25-65.6 0-90.5 2.7-2.5 30.4-27.1 193.9-103.3z m0.3-64.2c-8.9 0-18.2 1.9-27.4 6.2-88 41-186 90-212 116-50 50-50 131 0 181 25 25 57.8 37.5 90.5 37.5s65.5-12.5 90.5-37.5c26-26 75-124 116-212 21-44.9-14.1-91.2-57.6-91.2z",
        "p-id": "14774"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M514.90052 257c-219.1 0.3-398.5 182.2-395.9 401.3 0.3 29.2 3.9 57.7 10.2 85.1 3.3 14.4 16.3 24.6 31.1 24.6 20.6 0 35.8-19.1 31.2-39.2-5.7-24.5-8.5-49.7-8.5-75.3 0-44.9 8.8-88.5 26.1-129.4 16.8-39.6 40.7-75.2 71.3-105.7 30.5-30.6 66.1-54.5 105.7-71.3 41-17.3 84.5-26.1 129.4-26.1s88.5 8.8 129.4 26.1c39.6 16.8 75.2 40.7 105.7 71.3 30.6 30.5 54.5 66.1 71.3 105.7 17.3 41 26.1 84.5 26.1 129.4 0 25.6-2.9 50.8-8.5 75.3-4.6 20 10.6 39.2 31.2 39.2 14.9 0 27.8-10.3 31.2-24.8 6.7-28.8 10.2-58.8 10.2-89.7C912.00052 434.3 734.20052 256.7 514.90052 257z",
        "p-id": "14775"
      }
    }
  ]
})
    }
  