import Cookies from 'js-cookie'
import { getStorageItem } from '@/utils/storage'
export default {
  data () {
    return {
    }
  },
  computed: {
  
  },
  created () {
  },
  mounted () {
  },
  destroyed () {
  },

  methods: { 
    isArrayFn(value) {
      if (typeof Array.isArray === "function") {
        return Array.isArray(value);
      } else {
        return Object.prototype.toString.call(value) === "[object Array]";
      }
    },
    objToOne (obj) {
      console.log(obj)
      let tmpData = {}
      for (let index in obj) {
        if (typeof obj[index] == 'object' && !this.isArrayFn(obj[index])) {
          let resObj = this.objToOne(obj[index])
          Object.assign(tmpData, resObj) // 这里使用对象合并
        } else {
          tmpData[index] = obj[index]
        }
      }
      return tmpData
    },
    isArray (obj) {
      return this.getObjectType(obj) == 'array'
    },
    // 获取对象类型
    getObjectType (obj) {
      let toString = Object.prototype.toString
      let map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object',
      }
      if (obj instanceof Element) {
        return 'element'
      }
      return map[toString.call(obj)]
    },
    // 对象深拷贝
    deepClone (data) {
      let type = this.getObjectType(data)
      let obj
      if (type === 'array') {
        obj = []
      } else if (type === 'object') {
        obj = {}
      } else {
        // 不再具有下一层次
        return data
      }
      if (type === 'array') {
        for (let i = 0, len = data.length; i < len; i++) {
          data[i] = (() => {
            if (data[i] === 0) {
              return data[i]
            }
            return data[i]
          })()
          if (data[i]) {
            delete data[i].$parent
          }
          obj.push(this.deepClone(data[i]))
        }
      } else if (type === 'object') {
        for (let key in data) {
          if (data) {
            delete data.$parent
          }
          obj[key] = this.deepClone(data[key])
        }
      }
      return obj
    },
     // 根据数据字典，查询指定字典dict指定值code的，返回整个dictItem{id, text, extend}
     getDictItemByCode (dict, code) {
      let dicts = getStorageItem('AJReportDict')
      if (!dicts.hasOwnProperty(dict)) {
        return null
      }
      let dictItems = dicts[dict]
      for (let i = 0; i < dictItems.length; i++) {
        let dictItem = dictItems[i]
        if (typeof (code) == 'number') {
          code = code.toString()
        }
        if (dictItem['id'].toString() == code) {
          return dictItem
        }
      }
      return null
    },
    // 根据数据字典，查询指定字典dict指定值code的dictItem.text
    getDictLabelByCode (dict, code) {
      let dictItem = this.getDictItemByCode(dict, code)
      if (dictItem != null) {
        return dictItem['text']
      } else {
        return ''
      }
    },
    // 根据数据字典，查询指定字典dict指定值code的dictItem.extend
    getDictExtendByCode (dict, code) {
      let dictItem = this.getDictItemByCode(dict, code)
      if (dictItem == null) {
        return null
      }
      let extend = dictItem['extend']
      if (extend == null || extend.trim() == 'null') {
        return null
      }
      return dictItem['extend']
    },
    urlEncode (val) {
      return encodeURIComponent(val)
    },
    urlDecode (val) {
      return decodeURIComponent(val)
    },
    urlEncodeObject (obj, ingoreFields) {
      if (toString.call(obj) != '[object Object]') {
        return obj
      }
      let result = {}
      for (let key in obj) {
        if (this.isBlank(obj[key])) {
          continue
        }
        if (ingoreFields != null && ingoreFields.indexOf(key) >= 0) {
          result[key] = obj[key]
        } else {
          result[key] = this.urlEncode(obj[key])
        }
      }
      return result
    },
    getSettingByName(settingName) {
      if(localStorage.getItem('AJReportDict')!=null){
        let gaeaSetting = JSON.parse(localStorage.getItem('AJReportDict'))
        if (gaeaSetting[settingName] != null) {
          return gaeaSetting[settingName]
        } else {
          // console.error('没有找到系统参数' + settingName + '，请与后端联系')
          return null
        }
      }else{
        return null;
      }
      },
    isBlank (val) {
      if (typeof val === 'undefined') {
        return true
      }
      if (val == null || val === '') {
        return true
      }
      return false
    }, 
    isNotBlank (val) {
      return !this.isBlank(val)
    },
    isBlank (val) {
      if (this.isNull(val)) {
        return true
      }
      if (typeof val === 'string') {
        return val.trim() == ''
      }
      if (typeof val === 'object') {
        for (let key in val) {
          return false
        }
        return true
      }
      return false
    },
    isNotNull (val) {
      return !this.isNull(val)
    },
    isNull (val) {
      // 特殊判断
      if (val && parseInt(val) === 0) return false
      const list = ['$parent']
      if (val instanceof Date || typeof val === 'boolean' || typeof val === 'number') return false
      if (val instanceof Array) {
        if (val.length === 0) return true
      } else if (val instanceof Object) {
        val = this.deepClone(val)
        list.forEach((ele) => {
          delete val[ele]
        })
        for (let o in val) {
          return false
        }
        return true
      } else {
        if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') {
          return true
        }
        return false
      }
      return false
    },
  }
}
