
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1608880604126",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "8263",
    "width": "64",
    "height": "64"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M608.492308 675.446154c0 35.446154-43.323077 49.230769-63.015385 19.692308l-39.384615-84.676924c-21.661538-37.415385-68.923077-45.292308-104.369231-21.661538l-25.6 19.692308 131.938461 313.107692c5.907692 13.784615 19.692308 21.661538 35.446154 21.661538h346.584616c17.723077 0 31.507692-11.815385 35.446154-27.56923l61.046153-218.584616c15.753846-61.046154-19.692308-120.123077-74.830769-141.784615l-157.538461-53.169231c-222.523077-80.738462-149.661538 165.415385-145.723077 173.292308zM35.446154 561.230769h114.215384v114.215385H35.446154zM283.569231 78.769231h114.215384v114.215384h-114.215384z m0 748.307692h114.215384v114.215385h-114.215384zM35.446154 311.138462h114.215384v114.215384H35.446154z m0-230.4h114.215384v114.215384H35.446154zM533.661538 78.769231h114.215385v114.215384h-114.215385z m250.092308 0h114.215385v114.215384h-114.215385z m-748.307692 750.276923h114.215384v114.215384H35.446154z m748.307692-498.215385h114.215385v114.215385h-114.215385z",
        "p-id": "8264"
      }
    }
  ]
})
    }
  