<template>
  <a-config-provider :locale='locale'>
    <div id='app'>
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import "@/assets/iconfont/iconfont.css";
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
import { initDictToLocalstorage } from "@/api/report/dict-data";

export default {
  data() {
    return {}
  },
  created() {
    
      this.queryDictName();
  },
  methods: {
    queryDictName() {
      // 初始化数据字典到浏览器本地缓存
      initDictToLocalstorage(() => {
        this.isRouterAlive = true;
      });
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    }
  },
  computed: {
    locale() {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  }
}
</script>
