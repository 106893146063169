
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1608880429330",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "7479",
    "width": "64",
    "height": "64"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M575.071927 358.559828H925.850762L575.071927 77.615662v280.944166zM128.650825 0.999609h510.20125l382.648941 306.480188v612.960375c0 27.093583-13.434967 53.076905-37.357515 72.238906-23.922548 19.162001-56.359766 29.921157-90.194794 29.921156H128.650825c-33.835029 0-66.272247-10.759156-90.194795-29.921156-23.922548-19.162001-37.357515-45.145322-37.357515-72.238906V103.159672C1.098515 46.464431 57.849668 0.999609 128.650825 0.999609z m573.981399 817.2805V716.120047h-573.981399v102.160062h573.981399z m191.316483-204.320125V511.799922H128.650825v102.160062h765.297882z",
        "p-id": "7480"
      }
    }
  ]
})
    }
  