
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1644339072027",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "6532",
    "width": "200",
    "height": "200"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M811.52 197.12c-20.48-20.48-53.76-17.92-71.68 2.56-20.48 20.48-17.92 53.76 2.56 71.68 64 61.44 104.96 145.92 104.96 240.64 0 184.32-148.48 332.8-332.8 332.8-28.16 0-51.2 23.04-51.2 51.2s23.04 51.2 51.2 51.2c240.64 0 435.2-194.56 435.2-435.2-2.56-125.44-53.76-235.52-138.24-314.88zM179.2 512c0-184.32 148.48-332.8 332.8-332.8 28.16 0 51.2-23.04 51.2-51.2s-23.04-51.2-51.2-51.2C271.36 76.8 76.8 271.36 76.8 512c0 115.2 46.08 220.16 120.32 299.52 20.48 20.48 51.2 20.48 71.68 2.56 20.48-20.48 20.48-51.2 2.56-71.68C215.04 680.96 179.2 601.6 179.2 512z",
        "p-id": "6533",
        "fill": "#707070"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M614.4 675.84c5.12 28.16 33.28 46.08 61.44 38.4 28.16-5.12 46.08-33.28 38.4-61.44l-61.44-284.16C640 304.64 581.12 256 512 256s-128 48.64-143.36 115.2L307.2 655.36c-5.12 28.16 12.8 53.76 38.4 61.44 28.16 5.12 53.76-12.8 61.44-38.4l20.48-99.84h163.84l23.04 97.28z m-161.28-202.24l17.92-79.36c5.12-20.48 23.04-35.84 43.52-35.84s38.4 15.36 43.52 35.84l17.92 79.36h-122.88z",
        "p-id": "6534",
        "fill": "#707070"
      }
    }
  ]
})
    }
  