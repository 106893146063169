
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1644337740774",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "2929",
    "width": "200",
    "height": "200"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M623.920534 436.249759l-135.403876 330.911642-45.358047 0L571.857784 459.710077 398.91699 459.710077l0-38.654368 225.003544 0L623.920534 436.249759zM796.559453 127.977743c-15.954367 0-29.007661-13.053294-29.007661-29.007661l0-5.930059c0-15.953344-13.053294-29.006637-29.007661-29.006637l-5.961782 0c-15.954367 0-29.007661 13.053294-29.007661 29.006637l0 5.930059c0 15.954367-13.053294 29.007661-29.007661 29.007661l-326.004889 0c-15.954367 0-29.007661-13.053294-29.007661-29.007661l0-5.930059c0-15.953344-13.053294-29.006637-29.007661-29.006637l-5.961782 0c-15.954367 0-29.007661 13.053294-29.007661 29.006637l0 5.930059c0 15.954367-13.053294 29.007661-29.007661 29.007661L92.708983 127.977743c-15.954367 0-29.007661 13.053294-29.007661 29.007661l0 773.423011c0 15.954367 13.053294 29.007661 29.007661 29.007661l837.421604 0c15.954367 0 29.007661-13.053294 29.007661-29.007661L959.138248 156.984381c0-15.954367-13.053294-29.007661-29.007661-29.007661L796.559453 127.97672zM895.201054 866.497826c0 15.954367-13.053294 29.007661-29.007661 29.007661L156.665621 895.505487c-15.954367 0-29.007661-13.053294-29.007661-29.007661L127.65796 220.948181c0-15.953344 13.053294-29.006637 29.007661-29.006637l69.90614 0c15.954367 0 29.007661 13.053294 29.007661 29.006637l0 69.930699c0 15.953344 13.053294 29.006637 29.007661 29.006637l5.961782 0c15.954367 0 29.007661-13.053294 29.007661-29.006637l0-69.930699c0-15.953344 13.053294-29.006637 29.007661-29.006637l326.004889 0c15.954367 0 29.007661 13.053294 29.007661 29.006637l0 69.930699c0 15.953344 13.053294 29.006637 29.007661 29.006637l5.961782 0c15.954367 0 29.007661-13.053294 29.007661-29.006637l0-69.930699c0-15.953344 13.053294-29.006637 29.007661-29.006637l69.634963 0c15.954367 0 29.007661 13.053294 29.007661 29.006637L895.204124 866.497826z",
        "p-id": "2930",
        "fill": "#707070"
      }
    }
  ]
})
    }
  