
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1644377654697",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "3349",
    "width": "200",
    "height": "200"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M705.024 525.312l148.48-103.936-148.48-103.936v75.264h-235.52c-15.872 0-29.184 12.8-29.184 29.184 0 15.872 12.8 29.184 29.184 29.184h235.52v74.24z m-386.048-37.376l-148.48 103.936 148.48 103.936v-75.264h235.52c15.872 0 29.184-12.8 29.184-29.184 0-15.872-12.8-29.184-29.184-29.184h-235.52v-74.24zM388.608 846.848h-16.384v16.384c0-9.216 7.168-16.384 16.384-16.384z m-266.24-680.96h16.384v-15.872c0 8.192-6.656 15.872-16.384 15.872z",
        "p-id": "3350",
        "fill": "#707070"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M372.224 846.848h16.384c-9.216 0-16.384 7.168-16.384 16.384v-16.384H138.752v16.384c0-9.216-7.168-16.384-16.384-16.384h16.384v-680.96h-16.384c9.728 0 16.384-7.68 16.384-15.872v15.872h233.472v-16.384c0 9.216 7.168 16.384 16.384 16.384h-16.384v119.808h49.664V162.304a46.98 46.98 0 0 0-47.104-47.104h-238.08A46.98 46.98 0 0 0 89.6 162.304v688.128a46.98 46.98 0 0 0 47.104 47.104h238.08a46.98 46.98 0 0 0 47.104-47.104V738.304h-49.664v108.544z",
        "p-id": "3351",
        "fill": "#707070"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M138.752 863.232v-16.384h-16.384c9.216 0 16.384 7.168 16.384 16.384z m233.472-713.728v16.384h16.384c-9.216 0-16.384-7.168-16.384-16.384z m262.144 16.384h16.384v-15.872c0 8.192-6.656 15.872-16.384 15.872z",
        "p-id": "3352",
        "fill": "#707070"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M886.784 115.2h-238.08a46.98 46.98 0 0 0-47.104 47.104v123.392h49.152V165.888h-16.384c9.728 0 16.384-7.68 16.384-15.872v15.872h233.472v-16.384c0 9.216 7.168 16.384 16.384 16.384h-16.384v680.96h16.384c-9.216 0-16.384 7.168-16.384 16.384v-16.384H650.752v16.384c0-9.216-7.168-16.384-16.384-16.384h16.384V738.304H601.6v112.128a46.98 46.98 0 0 0 47.104 47.104h238.08a46.98 46.98 0 0 0 47.104-47.104V162.304a46.98 46.98 0 0 0-47.104-47.104z",
        "p-id": "3353",
        "fill": "#707070"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M900.608 846.848h-16.384v16.384c0-9.216 7.168-16.384 16.384-16.384z m-249.856 16.384v-16.384h-16.384c9.216 0 16.384 7.168 16.384 16.384z m233.472-713.728v16.384h16.384c-9.216 0-16.384-7.168-16.384-16.384z",
        "p-id": "3354",
        "fill": "#707070"
      }
    }
  ]
})
    }
  