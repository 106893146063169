<template>
  <div id='userLayout' :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class='top'>
        <div class='header'>
          <a href='/'>
            <img src='~@/assets/images/logo1.png' class='logo' alt='logo'>
          </a>
        </div>
        <div class='desc'>

        </div>
      </div>

    <div class='container'>
      

   <a-row class="middle">
    <a-col :span="12"  style="display:flex;align-item:center" > <img src='~@/assets/images/loginBg.png' alt='loginBg' class='loginBg'></a-col>
    <a-col :span="12">     <router-view /></a-col>
  </a-row>
  <div class="loginBoxBottom">
      <div class="login-copyright">
        <div class="bottom">
          <p>
            壹达创优 版权所有<a
              href="https://beian.miit.gov.cn/"
              target="“_blank”"
              >粤ICP备2021074199号</a
            >
          </p>
        </div>
      </div>
    </div>

    </div>
       
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'

export default {
  name: 'UserLayout',
  mixins: [deviceMixin],
  mounted() {
    document.body.classList.add('userLayout')
  },
  beforeDestroy() {
    document.body.classList.remove('userLayout')
  }
}
</script>

<style lang='less' scoped>
#userLayout.user-layout-wrapper {
  height: 100vh;
      background: #F6F5FF;
      overflow:hidden;
          padding: 32px 52px;
      

          .middle{
             position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
          }
  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  .container {
    width: 1200px;
    min-height: 100%;
    margin:0 auto;
    background:#F6F5FF;
    background-size: 100%;
    position: relative;
    .loginBg{
      max-width: 600px;
// margin-top:75px;
    }

    a {
      text-decoration: none;
    }

    .top {
      text-align: center;

      .header {
        height: 44px;
        line-height: 44px;

        .badge {
          position: absolute;
          display: inline-block;
          line-height: 1;
          vertical-align: middle;
          margin-left: -12px;
          margin-top: -10px;
          opacity: 0.8;
        }

        .logo {
         width: 148px;
    height: 31px;
          vertical-align: top;
          margin-right: 16px;
          border-style: none;
        }

        .title {
          font-size: 33px;
          color: rgba(0, 0, 0, .85);
          font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
          font-weight: 600;
          position: relative;
          top: 2px;
        }
      }

      .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
        margin-bottom: 40px;
      }
    }

    .main {
         width: 397px;
    padding: 5px 40px 47px 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    border-radius: 12px;
    margin-left: 18%;
    }
    .loginBoxBottom {
  /* width: calc(100vw - 700px); */
  width: 100%;
  position: absolute;
  bottom: 50px;
}
.login-copyright {
  color: #999;
  text-align: center;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-copyright .bottom {
  display: flex;
  justify-content: center;
}

.login-copyright .top {
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.login-copyright .bottom div a {
  color: inherit;
}

.login-copyright .bottom p {
  margin: 0;
  font-size: 14px;
}

.login-copyright .bottom .blue {
  font-size: 11px;
  color: #316aff;
}
.login-copyright .bottom a,
.login-copyright .bottom a:hover {
  color: #999;
}


  }
}
</style>
