
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1608877719378",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "3862",
    "width": "64",
    "height": "64"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M844.8 533.333333c0-21.845333-1.834667-43.093333-4.522667-64h95.829334V384h-114.986667a423.850667 423.850667 0 0 0-71.765333-134.314667l91.52-91.52-60.330667-60.330666-92.330667 92.288C637.056 150.997333 576.768 128 512 128s-125.056 22.997333-176.213333 62.122667L243.498667 97.834667 183.168 158.165333l91.52 91.52A423.850667 423.850667 0 0 0 202.922667 384H85.333333v85.333333h98.432c-2.730667 20.906667-4.565333 42.154667-4.565333 64 0 21.973333 1.834667 43.434667 4.608 64.469334H85.333333v85.333333h117.717334c9.728 29.568 22.186667 57.429333 37.034666 83.114667l-99.584 99.584 60.330667 60.330666 90.368-90.368C350.037333 899.541333 427.264 938.666667 512 938.666667s161.962667-39.125333 220.8-102.869334l90.368 90.368 60.330667-60.330666-99.584-99.584c14.890667-25.685333 27.349333-53.546667 37.034666-83.114667H938.666667v-85.333333h-98.517334c2.816-21.034667 4.650667-42.496 4.650667-64.469334zM512 213.333333c94.762667 0 177.109333 69.290667 218.709333 170.666667H293.290667C334.890667 282.624 417.237333 213.333333 512 213.333333z m42.666667 634.88V554.666667h-85.333334v293.546666c-116.181333-26.24-204.8-157.312-204.8-314.88 0-21.930667 1.706667-43.306667 4.992-64h484.906667c3.285333 20.693333 4.992 42.069333 4.992 64 0.042667 157.610667-88.576 288.64-204.757333 314.88z",
        "p-id": "3863"
      }
    }
  ]
})
    }
  